@import '@biocentury/components/styles/output.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Gotham;
    font-weight: 100;
    src: url(/fonts/woff2/Gotham-Thin.woff2) format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 200;
    src: url(/fonts/woff2/Gotham-XLight.woff2) format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 300;
    src: url(/fonts/woff2/Gotham-Light.woff2) format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 400;
    src: url(/fonts/woff2/Gotham-Book.woff2) format('opentype');
    font-display: swap;
  }
  @font-face {
    font-family: Gotham;
    font-weight: 500;
    src: url(/fonts/woff2/Gotham-Medium.woff2) format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 600;
    src: url(/fonts/woff2/Gotham-Bold.woff2) format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 700;
    src: url(/fonts/woff2/Gotham-Bold.woff2) format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 800;
    src: url(/fonts/woff2/Gotham-Black.woff2) format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: Gotham;
    font-weight: 900;
    src: url(/fonts/woff2/Gotham-Ultra.woff2) format('opentype');
    font-display: swap;
  }
}

/* purgecss start ignore */
.sidebar {
  transition: width 0.3s ease;
}

b {
  @apply font-bold;
}

.sidebar-open {
  width: 430px;
}

._right {
  float: right;
  clear: right;
  width: calc(50% - 15px);
  margin-left: 15px;
  padding: 0 !important;
}

.embed._right {
  width: calc(50% - 15px);
  margin-left: 15px;
}

._left {
  float: left;
  clear: left;
  width: calc(50% - 15px);
  margin-right: 15px;
  padding: 0 !important;
}

.embed._left {
  width: calc(50% - 15px);
  margin-right: 15px;
}

.editions-page-width {
  max-width: 840px;
}

@media screen and (min-width: 1180px) {
  .content-page-width {
    width: 1180px;
  }
  .utils-bar-width {
    width: 66px;
  }
  .article-content-width {
    width: 840px;
  }
  .right-rail-width {
    width: 224px;
  }
}

@media screen and (max-width: 1180px) {
  .content-page-width {
    width: 100%;
  }
  .article-content-width {
    width: 100%;
  }
}

/* purgecss end ignore */
